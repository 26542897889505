import React, { useState } from 'react';
import { Circles } from 'react-loader-spinner';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    date: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.phone) newErrors.phone = 'Phone is required';
    if (!formData.date) newErrors.date = 'Date is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false);
    } else {
      try {
        console.log(formData);
        const response = await fetch('https://amzvistas-backend-fwve.vercel.app/api/messages', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });

        if (response.ok) {
          setLoader(false);
          alert('Message successfully sent.');
          setFormData({
            name: '',
            phone: '',
            date: '',
            email: '',
            message: ''
          });
        } else {
          setLoader(false);
          alert('Failed to send message. Please try again.');
        }
      } catch (error) {
        setLoader(false);
        console.error('Error:', error);
        alert('Failed to send message. Please try again.');
      }
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="space-y-4">
          <h2 className="text-3xl font-bold mb-4">
            Contact <span className="gradient-text">Information</span>
          </h2>
          <p className="mb-4">
            Call/ Text/ Email us now to schedule a <span className="font-bold">FREE CONSULTATION</span> with one of our experts or leave us an email for a quick response.
          </p>
          <div className="flex items-center mb-4">
            <span className="text-yellow-500 mr-2">📍</span>
            <p>5900 Balcones Drive STE 100 Austin TX 78731</p>
          </div>
          <div className="flex items-center mb-4">
            <span className="text-yellow-500 mr-2">📞</span>
            <p>+1 (737) 295-1375</p>
          </div>
          <div className="flex items-center mb-4">
            <span className="text-yellow-500 mr-2">✉️</span>
            <p>contact@amzvistas.com</p>
          </div>
          <h3 className="text-2xl font-bold mt-8">
            Speak To An <span className="gradient-text">Expert</span>
          </h3>
        </div>
        <div className="space-y-4">
          <h2 className="text-3xl font-bold mb-4 text-center">
            Get In <span className="text-orange-500">Touch</span> With Us
          </h2>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div className="flex flex-col md:flex-row md:space-x-4">
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="flex-grow p-2 border border-gray-400	 rounded-md"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <p className="text-red-500">{errors.name}</p>}
              <input
                type="text"
                name="phone"
                placeholder="Phone"
                className="flex-grow p-2 border border-gray-400 rounded-md mt-4 md:mt-0"
                value={formData.phone}
                onChange={handleChange}
              />
              {errors.phone && <p className="text-red-500">{errors.phone}</p>}
            </div>
            <input
              type="date"
              name="date"
              placeholder="dd/mm/yyyy"
              className="w-full p-2 border border-gray-400 rounded-md"
              value={formData.date}
              onChange={handleChange}
            />
            {errors.date && <p className="text-red-500">{errors.date}</p>}
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="w-full p-2 border border-gray-400 rounded-md"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p className="text-red-500">{errors.email}</p>}
            <textarea
              name="message"
              placeholder="Message"
              className="w-full p-2 border border-gray-400 rounded-md"
              rows="4"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            {errors.message && <p className="text-red-500">{errors.message}</p>}
            {loader ? (
              <Circles
                height="30"
                width="30"
                color="#FFAC42"
                ariaLabel="circles-loading"
                wrapperClass="w-full flex justify-center"
                visible={true}
              />
            ) : (
              <button
                type="submit"
                className="w-full md:w-64 text-white font-bold py-2 px-4 custom-button themeBackground mainthemecolor"
                itemscope
                itemtype="http://schema.org/Button"
              >
                <span itemprop="name">Send</span>
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
