import React from 'react';

const WallmartAutomationStrategy = () => {
  return (
    <div className="bg-gray-200 py-12 px-4 sm:px-6 lg:px-8 Visionary-bg">
      <div className="max-w-6xl mx-auto text-center">
      <h1 className="text-xl md:text-3xl leading-9 font-bold text-white text-center mb-6">
      There is no risk with our Walmart Automation Strategy. If your investment doesn't yield a 100% return, we'll buy it back. Contact our Walmart automation team today to start a discussion about your business.



</h1>


<p  className='text-center text-white font-medium'>
Risk-Free Walmart Automation Strategy

</p>


      </div>
    </div>
  );
}

export default WallmartAutomationStrategy;
