import React from 'react';
import img1 from '../../Images/Sample1.jpg'

const AreAware = () => {
  return (
    <div className='bg-gray-100 py-12 px-4 sm:px-6 lg:px-8'>
      <div className='max-w-3xl mx-auto'>
        <h1 className='text-4xl leading-10 font-extrabold text-center text-gray-900'>
          Are You Aware?
        </h1>
        <p className='mt-2 text-lg leading-8 text-center text-gray-600'>
          Clients with the right working capital hit up to six-figure monthly revenues within 12 months.
        </p>
        <div className='mt-6 flex justify-center'>
          <img
            className='max-w-full h-auto'
            src={img1}
            alt='Awareness Image'
          />
        </div>
      </div>
    </div>
  );
}

export default AreAware;
