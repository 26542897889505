import React from 'react';
import './Discover.css'
const DiscoverExcellence = () => {
  return (
    <div className="text-center my-14 px-4 lg:px-24 flex flex-col justify-center items-center">
      <h1 className="text-2xl lg:text-4xl font-bold mb-4 text-gray-800">
      Discover the Excellence of  <span className='gradient-text' > Amz Vistas Amazon FBA </span>  Automation
      </h1>
      <p className="my-2 text-md  text-gray-700">
      Unlock the full potential of your Amazon automation investments at Amz Vistas, where pride meets innovation. Intrigued?

      </p>
      <div className="my-2 text-md md:w-3/5 text-gray-700">
  <p className="mb-2">
  Experience top-tier Amazon automation services crafted for business elevation. We specialize in empowering e-commerce endeavors, fostering growth, and establishing a thriving passive income stream.
  </p>
  <p>
  With Amz Vistas, you're not just getting a service provider but a team of dedicated Amazon experts ready to catapult your business to unprecedented heights. Automation is not just a service; it's a journey to unparalleled success.
  </p>
</div>

    </div>
  );
};

export default DiscoverExcellence;
