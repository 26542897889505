import React from 'react';
import { Link } from 'react-router-dom';

const WallmartFeelConfident = () => {
  return (
    <div className="bg-gray-200 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto text-center">
      <h1 className="text-xl md:text-3xl leading-9 font-bold text-gray-900 mb-6 text-center">
    Our Walmart Automation Strategy has no risk; if your investment doesn't give you a 100% return, we'll repurchase it. Please feel free to call our Walmart.
</h1>

        <Link to={"/Contact-us"} >
        <button  className="w-full md:w-auto text-white font-bold py-2 px-4  custom-button themeBackground mainthemecolor">
          <span>Book Call</span>
        </button>
        
        </Link>
      </div>
    </div>
  );
}

export default WallmartFeelConfident;
