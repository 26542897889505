import React from 'react';
import { Link } from 'react-router-dom';

const Revolution_Card = ({ title, title2, description, buttonText }) => {
  return (
    <div className="bg-white shadow-2xl rounded-lg overflow-hidden m-4 max-w-lg mx-auto text-center">
      <div className="px-6 py-8">
        <h1
          className="text-2xl font-bold text-gray-800 mb-4"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h1>
         {title2 && (
<h1
    className="text-2xl font-bold text-gray-800 mb-4"
    dangerouslySetInnerHTML={{ __html: title2 }}
    >

</h1>
)}

        <p className="text-gray-700 text-lg mb-6">{description}</p>
        <Link to={"/Contact-us"} >
        {buttonText && (
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded shadow-md mainthemecolor" >
            {buttonText}
          </button>
        )}
        </Link>
      </div>
    </div>
  );
};

export default Revolution_Card;
