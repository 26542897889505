import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SliderCard2 from './Slider_Card';
import image1 from '../../Images/Sustainable1.png'
import image2 from '../../Images/Sustainable2.png'
import image3 from '../../Images/Sustainable3.png'


const CardSlider1 = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true // Show dots on smaller screens for navigation
                }
            }
        ]
    };

    return (
        <div className="Visionary-bg1">
            <h1 className="font-medium text-xl sm:text-3xl md:text-3xl lg:text-3xl text-center text-white py-6 md:py-12">
             Diversify Your Portfolio with a Sustainable Passive Income Stream – Introducing <br className="hidden md:inline" /> Amz Vistas's Amazon FBA and Walmart Automation Models!
            </h1>

            <div className="w-full md:w-3/4 mx-auto">
                <Slider {...settings} className="mx-auto">
                    <div>
                        <SliderCard2
                            imageSrc={image1}
                            title="Wholesale Wonder"
                            description="Secure branded treasures in bulk and revel in volume savings. Make your mark by selling on the market and watch your profits soar."
                        />
                    </div>
                    <div>
                        <SliderCard2
                            imageSrc={image2}
                            title="High-Octane Reselling"
                            description="Experience the thrill of selling high-frequency inventory (FBM) to the end consumer in under 7 days. Our Dallas facilities take charge of all fulfillment."
                        />
                    </div>
                    <div>
                        <SliderCard2
                            imageSrc={image3}
                            title="Private Label Prowess"
                            description="Amazon and Walmart sellers can now showcase products under their own brand. This ensures a jaw-dropping 35% margin or higher. Transform your income streams today!"
                        />
                    </div>
                    <div>
                        <SliderCard2
                            imageSrc={image1}
                            title="Wholesale Wonder"
                            description="Secure branded treasures in bulk and revel in volume savings. Make your mark by selling on the market and watch your profits soar."
                        />
                    </div>
                    <div>
                        <SliderCard2
                            imageSrc={image3}
                            title="High-Octane Reselling"
                            description="Experience the thrill of selling high-frequency inventory (FBM) to the end consumer in under 7 days. Our Dallas facilities take charge of all fulfillment."
                        />
                    </div>
                    <div>
                        <SliderCard2
                            imageSrc={image2}
                            title="Private Label Prowess"
                            description="Amazon and Walmart sellers can now showcase products under their own brand. This ensures a jaw-dropping 35% margin or higher. Transform your income streams today!"
                        />
                    </div>
                </Slider>
            </div>
        </div>
    );
};

export default CardSlider1;
