import React from 'react';
import Revolution_Card from './Revolution_Card';
import '../Discover_Excellence/Discover.css'
const Revolutionize = () => {
  return (
    <div className="py-6">
      <div className="max-w-6xl mx-auto text-center">
        <h1 className="text-3xl font-bold  mb-8">
          Revolutionize Your E-Commerce Journey
        </h1>
        <h2 className="text-2xl  mb-8 font-bold">
          Timelines and Predictable Growth Of Your  <span   className="gradient-text" >E-Commerce Assets</span> 
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="max-w-lg mx-auto">
            
          <Revolution_Card
        title="Welcome to the zone of sustainable assets with our <span class='gradient-text'>Amazon FBA Business</span> Owner program."
        description="We promise long-term success and the highest growth rate. Stand out and dominate the Amazon marketplace as a Pro Seller, armed with our distinctive hybrid approach, winning Buy-Box strategy, and exclusive product research tactics. Over 200 successful Amazon FBA businesses have thrived using our proven model."
        buttonText="Schedule A Free Consultation"
      />

          </div>
          <div className="max-w-lg mx-auto">
          <Revolution_Card
  title="Dive into the Opportunity of the Decade with  <span  class='gradient-text'>Walmart WFS! </span> "
  description="Witness rapid growth to six figures and the highest ROI by investing in our Walmart WFS Automation Program. With less than 200,000 marketplace sellers and a staggering $14 billion in e-commerce revenue, Walmart Online Marketplace presents millions of opportunities for new sellers. Seize the moment and embark on a journey to unparalleled success!"
  buttonText="Schedule A Free Consultation"
/>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Revolutionize;
