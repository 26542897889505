import React from 'react';
import './Discover.css';

const DiscoverExcellence2 = () => {
  return (
    <div className="text-center my-14 px-4 lg:px-24 flex flex-col justify-center items-center">
      <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 text-gray-800">
        <span className='gradient-text'>Unlock the Potential of Wholesale Amazon Automation Companies </span>
      </h1>
      <p className="my-2 text-sm sm:text-md lg:text-lg text-gray-700 w-full md:w-4/5 lg:w-3/5">
      Discover incredible income opportunities through Amazon automation dropshipping with Amz Vistas. No need for a hefty marketing budget – explore the potential to earn five figures or more in monthly profit!
      </p>
    </div>
  );
};

export default DiscoverExcellence2;
