import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SliderCard from './Slider_Card';

const CardSlider3 = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="Visionary-bg">
            
            <div className="w-3/4 mx-auto">
                <Slider className="mx-auto" {...settings}>
                    <div>
                        <SliderCard
                            imageSrc="https://walstreamz.com/wp-content/uploads/2024/03/protest.png"
                            title="Digital Real Estate"
                            description="The Walmart WFS business is a valuable digital product that could be worth 25 to 35 times your monthly net profit."
                        />
                    </div>
                    <div>
                        <SliderCard
                            imageSrc="https://walstreamz.com/wp-content/uploads/2024/03/cyber-security.png"
                            title="Fully Automated"
                            description="Our fully automated solutions mix AI product research with custom order fulfillment to make your business run smoothly while you  income."
                        />
                    </div>
                    <div>
                        <SliderCard
                            imageSrc="https://walstreamz.com/wp-content/uploads/2024/03/stats.png"
                            title="Online and trade arbitrage"
                            description="Our automated Walmart E-commerce store has a solid, sustainable business plan from day one,  peace of mind."

                        />
                    </div>
                    <div>
                        <SliderCard
                            imageSrc="https://walstreamz.com/wp-content/uploads/2024/03/experience.png"
                            title="Start making idle income."
                            description="Take advantage of the chance to get a new source of passive income and see your investments overgrow."
                        />
                    </div>
                    <div>
                        <SliderCard
                            imageSrc="https://walstreamz.com/wp-content/uploads/2024/03/protest.png"
                            title="Digital Real Estate"
                            description="The Walmart WFS business is a valuable digital product that could be worth 25 to 35 times your monthly net profit."
                        />
                    </div>
                    <div>
                        <SliderCard
                            imageSrc="https://walstreamz.com/wp-content/uploads/2024/03/cyber-security.png"
                            title="Fully Automated"
                            description="Our fully automated solutions mix AI product research with custom order fulfillment to make your business run smoothly while you enjoy passive income."
                        />
                    </div>
                </Slider>
            </div>
        </div>
    );
};

export default CardSlider3;
