import React from 'react'

const AboutSec = () => {
  return (
    <div className="text-center mb-16">
        <h1 className="text-3xl font-bold text-gray-800">
        <span className="gradient-text">Mission</span> Statement
        </h1>
        <p className="text-gray-600 mt-4 max-w-6xl mx-auto">
        Our team recognizes that one of the most demanding aspects of an e-commerce business is efficiently managing orders and meeting customer needs in a timely manner. This can be a time-consuming process, and achieving a high level of automation in online retail requires a dedicated and skilled team. That's where we come in. We've developed an infrastructure that enables you to automate and streamline all the essential aspects of running a successful e-commerce operation. Our mission is to provide you with the solutions necessary to save time, reduce costs, increase revenue, and ultimately create a source of passive income.
        </p>
        <p className="text-gray-600 mt-4 max-w-6xl mx-auto">
        With the trust of over 300 clients and a track record of generating eight figures in revenue over the past decade, we are committed to ensuring that your online store operates seamlessly and effectively. Whether you're just starting in e-commerce or looking to enhance your existing store, we have you covered. We offer comprehensive services, including Done For You Walmart WFS, Amazon Wholesale FBA, and Shopify Automation. Our services encompass inventory management, order processing, shipping, and top-notch customer support. We also tailor unique solutions to meet the specific needs of your business.
        </p>


        <p className="text-gray-600 mt-4 max-w-6xl mx-auto">
        As you collaborate with Amz Vistas, watch your passive income potential grow. We eagerly anticipate the opportunity to assist you in expanding your business and reaching your entrepreneurial aspirations.
        </p>
      </div>
  )
}

export default AboutSec