import React from 'react';

const Four_Card = () => {
  return (
    <div className="py-12">
      <div className="max-w-6xl mx-auto text-center">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="p-4 bg-white rounded-lg ">
            <h1 className="text-3xl font-bold text-gray-800 mb-2 themetextcolor">100+</h1>
            <p className="text-base text-gray-700">Years Combined Leadership <br /> Experience In Ecommerce Logistics <br /> And Tech Industry</p>
          </div>
          <div className="p-4 bg-white rounded-lg ">
            <h1 className="text-3xl font-bold text-gray-800 mb-2 themetextcolor">100+</h1>
            <p className="text-base text-gray-700">Million In Amazon Revenue</p>
          </div>
          <div className="p-4 bg-white rounded-lg ">
            <h1 className="text-3xl font-bold text-gray-800 mb-2 themetextcolor">12+</h1>
            <p className="text-base text-gray-700">Exclusive Logistics Centers</p>
          </div>
          <div className="p-4 bg-white rounded-lg ">
            <h1 className="text-3xl font-bold text-gray-800 mb-2 themetextcolor">1,000+</h1>
            <p className="text-base text-gray-700">Brand Licensing Partners</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four_Card;
