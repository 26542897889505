import React from 'react'
import MegaMenu from '../../Components/Navbar/Navbar'
import ContactBanner from '../../Components/Banner/ContactBanner'
import ContactForm from '../../Components/Contact_Form/Contact_Form'
import Footer from '../../Components/Footer/Footer'
import Reviews from '../../Components/Reviews/Reviews'

const Contact = () => {
  return (
  <>
      <MegaMenu/>
      <div className='py-20' >
<ContactBanner/>
      </div>

      <ContactForm/>


      <Reviews/>

<Footer/>


  </>
  )
}

export default Contact