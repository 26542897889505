import React from 'react'
import MegaMenu from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import Terms_FAQ from '../../Components/Terms_Condition/Terms_Conditionar'

const Terms_Condition = () => {
  return (
<>
<MegaMenu/>

<div >
<Terms_FAQ/>
</div>
<Footer/>
</>
  )
}

export default Terms_Condition