import React from 'react'
import MegaMenu from '../../Components/Navbar/Navbar'
import OurProcessBanner from '../../Components/Banner/OuProccessBanner'
import { OneSideImageText } from '../../Components/OneSideImageText/OneSideImageText'
import GiveChance from '../../Components/Discover_Excellence/GiveChance'
import Footer from '../../Components/Footer/Footer'
import Reviews from '../../Components/Reviews/Reviews'
import { TakeCare } from '../../Components/Discover_Excellence/TakeCare'
import Steps from '../../Components/Steps/Steps'
import ourAmzvistas from '../../Images/OurProcess2.jpg'


const Our_Process = () => {
  return (
   <>
   
   <MegaMenu/>
   <div className='py-20' >
   <OurProcessBanner/>
   </div>
   <OneSideImageText
      imageSrc={ourAmzvistas}
      heading="Our <span class='gradient-text' >  Amz Vistas Process </span>"
      text1="With the help of our tested infrastructure, smooth-running onboarding process, and the 10+ years of expertise in Amazon and Walmart that we dedicate to every client, we want to make your Amz Vistas e-commerce business the most flexible and long-lasting asset in your portfolio"
    />
    {/* <GiveChance/> */}
<Steps/>


    <TakeCare/>
    <Reviews/>

<Footer/>
   </>
  )
}

export default Our_Process