
import React, { useState } from 'react';
import "./Navbar.css"; // CSS for styling
import { Link  , useNavigate } from 'react-router-dom';
import logo from '../../Images/Logo.png'
import '../../App.css'

const MegaMenu = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [dropdown, setDropdown] = useState(false)
    const [dropdown1, setDropdown1] = useState(false)
    const [dropdown2, setDropdown2] = useState(false)
    const [dropdown3, setDropdown3] = useState(false)
  
  const navigate = useNavigate()
  
    const toggleMobileMenu = () => {
      setMobileMenuOpen(prevState => !prevState);
    };
    const handleClick = () => {
      window.location.href = 'tel:+17373591874';
    };
  
  
    function handleClick23() {
      navigate("/Contact-us");
    }
  
    return (
      <nav 
      style={{
        position: 'fixed',
        zIndex: '100',
      }}
      className="navbar nav-with-shadow bg-white border-gray-200 py-4">
        <div className="navbar-container">
     <Link  to={"/"} >
  
  
     <img  className='h-16 md:mx-12 mx-4'  src={logo} alt="The Readsy Testimonials" />
  
        
        
        </Link>
  
          <div className="navbar-hamburger" onClick={toggleMobileMenu}>
            ☰
          </div>
  
          <ul className={`navbar-links ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
  
          <Link itemprop="availability" href="https://schema.org/InStock"
                className="listitemcolorhover  block py-2 pl-3 pr-4 text-white rounded  md:p-0   font-medium "
                to={'/'}
              >
                Home
              </Link>
              <Link itemprop="availability" href="https://schema.org/InStock"
                className="listitemcolorhover block  pl-3 pr-4 text-white rounded  md:p-0 font-medium"
                to={'/About-us'}
              >
               About Us
              </Link>

            <li className="with-dropdown">
            <Link itemprop="availability" href="https://schema.org/InStock"
                className="listitemcolorhover block py-2 pl-3 pr-4 text-white rounded  md:p-0   font-medium"
                to={'/'}
              >
                Services  <i class="fa fa-caret-down" aria-hidden="true"></i>
              </Link>
              
              <ul className="dropdown">
  
  <li  className="nested-dropdown-container listitemcolorhover"  >
        
  <Link style={{"color" :"white"}} to={"/Amazon-fba-automation"} >
  Amazon FBA Automation
  </Link>
       
  </li>
  
                <li  className="listitemcolorhover nested-dropdown-container"  >
                  <Link style={{"color" :"white"}}  to={"/Shopify-Automation"} >
                  Shopify Automation
                  </Link>
  </li>
  <li  className="listitemcolorhover nested-dropdown-container"  >
                  <Link  style={{"color" :"white"}}  to={"/Walmart-Automation"} >
                  Walmart Automation
                  </Link>
  </li>

  
  
              </ul>
            </li>
  <li   className='mb-dropdown' >
  
  
  <Link itemprop="availability" href="https://schema.org/InStock"
                className="listitemcolorhover block py-2 pl-3 pr-4 text-white rounded  md:p-0"
                onClick={()=>{
                  setDropdown(!dropdown)
                }}
              >
                Services  <i class="fa fa-caret-down" aria-hidden="true"></i>
              </Link>
  {
    dropdown ? <>
    
    
  <li className='listitemcolorhover'>
    <Link style={{"color" :"black"}} to={"/Amazon-fba-automation"} >

    Amazon FBA Automation
  </Link>
       
  </li>
  
                <li   className="listitemcolorhover block  py-2 pl-3 pr-4 text-white rounded  md:p-0"  >
                  <Link style={{"color" :"black"}}  to={"/Shopify-Automation"} >
                  Shopify Automation
                  </Link>
  </li>
  <li    >
                  <Link    className="listitemcolorhover block py-2 pl-3 pr-4 text-white rounded  md:p-0" style={{"color" :"black"}}  to={"/Walmart-Automation"} >
                  Walmart Automation
                  </Link>
  </li>

  
  
  
    </> : ''
  
  }
  
  
  </li>
  
  
  
  
  
  
           
          
        
         
            <li>
             <Link itemprop="availability" href="https://schema.org/InStock"
                className="listitemcolorhover block text-white rounded  md:p-0 font-medium"
                to={'/Our-Process'}
              >
               Our Process

              </Link>
            
            </li>
  
  
  {/* <Link   to={'/Contact-us'} >  */}
  <button
  onClick={handleClick23}
    className="w-full md:w-auto text-white font-bold py-2 px-4  custom-button themeBackground mainthemecolor "
    itemscope
    itemtype="http://schema.org/Button"
  >
    <span itemprop="name">Contact Us </span>
  </button>
  {/* </Link> */}
          </ul>
        </div>
      </nav>
    );
  };
  
  export default MegaMenu;