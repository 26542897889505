import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SliderCard from './Slider_Card';
import image1 from '../../Images/Diversifyimag1.png'
import image2 from '../../Images/Diversifyimage2.png'
import image3 from '../../Images/Diversifyimage3.png'
import image4 from '../../Images/Diversifyimage4.png'
import image5 from '../../Images/Diversifyimag5.png'
import image6 from '../../Images/Diversifyimag6.png'


const CardSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="Visionary-bg">
            <h1 className="text-lg md:text-3xl leading-tight font-medium text-center text-white py-6 md:py-12 w-full">
            Diversify Your Portfolio with a Sustainable Passive Income Stream – Introducing  <br className="hidden md:inline" /> Amz Vistas Amazon FBA and Walmart Automation Models!</h1>


            <div className="w-3/4 mx-auto">
                <Slider className="mx-auto" {...settings}>
                    <div>
                        <SliderCard
                            imageSrc={image1}
                            title="Experience Long-Term Prosperity with Amz Vistas's Walmart Automation Method"
                            description="Our revenue-centric approach is designed for maximum profitability and is fully managed by our expert team. An automated Walmart store becomes a fresh and consistent source of income"
                        />
                    </div>
                    <div>
                        <SliderCard
                            imageSrc={image2}
                            title="Elevate Your Business with Walmart's Done for You Store."
                            description="Let Amz Vistas's skilled and experienced Walmart strategists take the wheel, managing every aspect of your store to preserve, scale, and enhance profit margins. Sit back, relax, and let us handle the rest."
                        />
                    </div>
                    <div>
                        <SliderCard
                            imageSrc={image3}
                            title="Get Started Today with Our Secure Walmart Automation Plan!"
                            description=" Our strategy comes with a guarantee – if your investment doesn't generate 100%, we'll repurchase it. Collaborate closely with us for product listing optimization, stock control, and marketplace consulting."
                        />
                    </div>
                    <div>
                        <SliderCard
                            imageSrc={image4}
                            title="Uninterrupted Support"
                            description="To us, you're not just a client but a valued partner. Access our 24/7 communication hub, reinforced by our dedicated Client Relations Officer (CRO), who ensures every account operates seamlessly. Experience unwavering support whenever you need it that have scaled new heights  techniques."
                        />
                    </div>
                    <div>
                        <SliderCard
                            imageSrc={image5}
                            title="Holistic E-commerce Management"
                            description="Once onboard, sit back and let us handle it all – from trademark licensing to product resourcing, responding to customer feedback, and even supporting you with annual tax returns. Your e-commerce success is our priority, and we're committed"
                        />
                    </div>
                    <div>
                        <SliderCard
                            imageSrc={image6}
                            title="Unlock the path to success!"
                            description=" Now, you can soar as an Amazon FBA business owner with our revolutionary hybrid strategy and exclusive product research tactics. Join the ranks of over 200 thriving Amazon FBA businesses that have scaled new heights using our proven techniques."
                        />
                    </div>
                </Slider>
            </div>
        </div>
    );
};

export default CardSlider;
