import React from 'react';
import { Link } from 'react-router-dom';

const FeelConfident = () => {
  return (
    <div className="bg-gray-200 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto text-center">
      <h1 className=" text-xl md:text-3xl leading-8 md:leading-9 font-bold text-gray-900 mb-6 text-center md:text-text-center gradient-text">
      Launch Your Project Today with Our Amazon FBA Automation Services
</h1>
<p className="text-base md:text-lg leading-7 text-gray-900 text-center md:text-center my-4">
Making the decision to automate your Shopify store is an integral step toward increasing efficiency and profitability. Join us on this exciting journey with Amz Vistas.
</p>

        <Link to={"/Contact-us"} >
        <button  className="w-full md:w-auto text-white font-bold py-2 px-4  custom-button themeBackground mainthemecolor">
          <span>Book Call</span>
        </button>
        </Link>
      </div>
    </div>
  );
}

export default FeelConfident;
