import React from 'react';

export const TakeCare = () => {
  return (
    <div className="flex flex-col justify-center items-center my-12">
      <h1 className="text-3xl md:text-3xl font-bold my-6 text-center">
        We Take Care of the <span className="gradient-text">Following</span>
      </h1>
      <div className="flex flex-wrap text-white w-3/4 mb-4 justify-center">
        <ul className="list-none">
          <li className="li-st mb-2 text-black text-md font-medium text-center md:text-left flex items-start">
            <i className="li-ico fas fa-check font-bold md:mr-2 mt-2 text-[#FF8818]"></i>
            <div className="li-st-txt">Process of Onboarding + Strategy</div>
          </li>
          <li className="li-st mb-2 text-black text-md font-medium text-center md:text-left flex items-start">
            <i className="li-ico fas fa-check font-bold md:mr-2 mt-2 text-[#FF8818]"></i>
            <div className="li-st-txt">DUNS, W9, LLC, and EIN support</div>
          </li>
          <li className="li-st mb-2 text-black text-md font-medium text-center md:text-left flex items-start">
            <i className="li-ico fas fa-check font-bold md:mr-2 mt-2 text-[#FF8818]"></i>
            <div className="li-st-txt">Exemptions from taxes in all 50 states</div>
          </li>
          <li className="li-st mb-2 text-black text-md font-medium text-center md:text-left flex items-start">
            <i className="li-ico fas fa-check font-bold md:mr-2 mt-2 text-[#FF8818]"></i>
            <div className="li-st-txt">Platform optimization,</div>
          </li>
          <li className="li-st mb-2 text-black text-md font-medium text-center md:text-left flex items-start">
            <i className="li-ico fas fa-check font-bold md:mr-2 mt-2 text-[#FF8818]"></i>
            <div className="li-st-txt">The setting up of a backend shop on the Walmart platform</div>
          </li>
          <li className="li-st mb-2 text-black text-md font-medium text-center md:text-left flex items-start">
            <i className="li-ico fas fa-check font-bold md:mr-2 mt-2 text-[#FF8818]"></i>
            <div className="li-st-txt">Product research, review, and product listing</div>
          </li>
          <li className="li-st mb-2 text-black text-md font-medium text-center md:text-left flex items-start">
            <i className="li-ico fas fa-check font-bold md:mr-2 mt-2 text-[#FF8818]"></i>
            <div className="li-st-txt">Processing orders and repricing
            </div>
          </li>
          <li className="li-st mb-2 text-black text-md font-medium text-center md:text-left flex items-start">
            <i className="li-ico fas fa-check font-bold md:mr-2 mt-2 text-[#FF8818]"></i>
            <div className="li-st-txt">Management of supplies and logistics</div>
          </li>
          <li className="li-st mb-2 text-black text-md font-medium text-center md:text-left flex items-start">
            <i className="li-ico fas fa-check font-bold md:mr-2 mt-2 text-[#FF8818]"></i>
            <div className="li-st-txt">SKUs are being sent to Amazon and Walmart to avoid return fees.</div>
          </li>
          <li className="li-st mb-2 text-black text-md font-medium text-center md:text-left flex items-start">
            <i className="li-ico fas fa-check font-bold md:mr-2 mt-2 text-[#FF8818]"></i>
            <div className="li-st-txt">Tracking and shipment confirmation</div>
          </li>
          <li className="li-st mb-2 text-black text-md font-medium text-center md:text-left flex items-start">
            <i className="li-ico fas fa-check font-bold md:mr-2 mt-2 text-[#FF8818]"></i>
            <div className="li-st-txt">Claims and Chargebacks Management</div>
          </li>
          <li className="li-st mb-2 text-black text-md font-medium text-center md:text-left flex items-start">
            <i className="li-ico fas fa-check font-bold md:mr-2 mt-2 text-[#FF8818]"></i>
            <div className="li-st-txt">Processing and handling infringement claims</div>
          </li> 
        </ul>
      </div>
    </div>
  );
};
