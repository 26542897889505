import React from 'react'
import MegaMenu from '../../Components/Navbar/Navbar'
import AmazonBanner from '../../Components/Banner/AmazonBanner'
import { OneSideImageText } from '../../Components/OneSideImageText/OneSideImageText'
import Revolutionize from '../../Components/Revolutionize/Revolutionize'
import Revolution_Card from '../../Components/Revolutionize/Revolution_Card'
import CardSlider from '../../Components/Slider_Card/Slider_Parent'
import Discover_Excellence from '../../Components/Discover_Excellence/Discover_Excellence'
import DiscoverExcellence2 from '../../Components/Discover_Excellence/Discover_Excellence2'
import { ListOneSideImageText } from '../../Components/OneSideImageText/ListOneSideImageText'
import ListOpposeOneSideImageText from '../../Components/OneSideImageText/ListOppose'
import Reviews from '../../Components/Reviews/Reviews'
import Footer from '../../Components/Footer/Footer'
import AreAware from '../../Components/AreAware/AreAware'
import FeelConfident from '../../Components/AreAware/FeelConfident'
import AmazonReviews from '../../Components/Reviews/Amazon_FBA'
import amazon1 from '../../Images/amazon1.jpg'
import amazon2 from '../../Images/Amazon2.png'
import amazon3 from '../../Images/Amazon3.png'
import amazon4 from '../../Images/Amazon4.png'

const Amazon_FBA_Automation = () => {
    const imageSrc = amazon2
    const listItems = [
      "Assistance in establishing LLC, obtaining EIN, and setting up W9",
      "Creation of e-commerce website with product setup",
      "Amazon FBA account setup",
      "Comprehensive management of Amazon FBA seller account",
      "Engagement and communication with brands",
    ];
  
const image2= amazon4
const listItems2 = [
    "Competitor research for identifying winning products",
    "Options for Shipping Products from Seller to Prep Center",
    "Guaranteed profit margins of 15–25%",
    "Strategies for winning the Buy Box",
    "Designated Amazon wholesale automation expert",
  ];

  const image3= amazon3
  const listItems3 = [
      "Approval acquisition to become official wholesalers for brands",
      "Unlimited product listing creation",
      "Management of orders and inventory",
      "Order fulfillment for 12 months at our warehouse facility",
    ];


  return (
    <>
      <MegaMenu/>
      <div className='pt-12' >
      <AmazonBanner/>
      </div>
      <OneSideImageText
      imageSrc={amazon1}
      heading="Immerse Yourself in Our <span class='gradient-text' > AI-Centric Amazon FBA Automation Model – No Ad Budget Required!  <span/> "
      text1="Experience profitability within the first month of launch. With decades of e-commerce mastery, we've crafted sustainable assets, aligning our success with yours. Join us in a model where the interests of our business seamlessly intertwine with the prosperity of our clients. Elevate your e-commerce journey with innovation and profitability at every turn!"
    //   text2="Our journey has been nothing short of remarkable. Our team has achieved a whopping $300 million in revenue through countless challenges – and we're just getting started! What sets us apart is our rock-solid business model that consistently spells success. We're not here to make promises; we're here to deliver, and we're so confident in our abilities that we offer you something extraordinary – a contractual guarantee of a return on investment (ROI) throughout our partnership."
      buttonText="Get A Free Consultation Now!"
    />

<Revolution_Card
              title="Revolutionize Your Finances with <span class='gradient-text' > Amz Vistas's Automated Investment Portfolio for Sustainable & Scalable Cash Flow!<span/> "
              description="Empower your finances to work for you with Amz Vistas. Discover an automated investment portfolio designed for sustainable and scalable cash flow."
            />

<CardSlider/>
<Revolution_Card
              title="Invest with Confidence in Amazon Seller Automation!"
              title2="<span class='gradient-text' >Amz Vistas offers a Buyback Guarantee ensuring a 100% ROI on your investment.</span>"
              buttonText={"Get A Free Consultation Now!"}
            />
<DiscoverExcellence2/>


<ListOneSideImageText imageSrc={imageSrc} listItems={listItems} />

<ListOpposeOneSideImageText imageSrc={image3} listItems={listItems3} />


<ListOneSideImageText imageSrc={image2} listItems={listItems2} />
<AreAware/>
<AmazonReviews/>
<FeelConfident/>


<Footer/>
    
    </>
  )
}

export default Amazon_FBA_Automation