import React from 'react';
import './Discover.css';

const DiscoverExcellence4 = () => {
  return (
    <div className="text-center my-14 px-4 lg:px-24 flex flex-col justify-center items-center">
      <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 text-gray-800">
        <span className='gradient-text'>Walmart FBA Brands in Bulk</span>
      </h1>
      <p className="my-2 text-sm sm:text-md lg:text-lg text-gray-700 w-full md:w-4/5 lg:w-3/5">
      Did you know that using Walmart Dropshipping software can generate significant income with minimal effort? People are talking about the opportunity to make at least $5,000 a month.





      </p>
    </div>
  );
};

export default DiscoverExcellence4;
