import React from 'react';
import img1 from '../../Images/6rta.jpg'

const AreAware2 = () => {
  return (
    <div className='bg-gray-100 py-12 px-4 sm:px-6 lg:px-8'>
      <div className='max-w-3xl mx-auto'>
        <h1 className='text-4xl leading-10 font-extrabold text-center text-gray-900 gradient-text'>
        No Other Shopify Store Is as Automated!

        </h1>
        <p className='mt-2 text-lg leading-8 text-center text-gray-600'>
        At Amz Vistas, we develop strategies that yield positive outcomes for both clients and businesses alike. Join us in climbing the ladder of success!
        </p>
        <div className='mt-6 flex justify-center'>
          <img
            className='max-w-full h-auto'
            src={img1}
            alt='Awareness Image'
          />
        </div>
      </div>
    </div>
  );
}

export default AreAware2;
