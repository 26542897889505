import React from 'react';
import step1 from '../../Images/Step1.png'
import step2 from '../../Images/Step2.png'
import step3 from '../../Images/Step3.jpg'



const Steps = () => {
  return (
    <div className="flex flex-col items-center py-16">
      <div className="text-center mb-16">
        <h1 className="text-3xl font-bold text-gray-800">
          Give Us A <span className="text-yellow-500">Chance</span> To Build Your <span className="gradient-text">Assets</span>
        </h1>
        <p className="text-gray-600 mt-4 max-w-2xl mx-auto">
          Our team of professionals is committed to providing outstanding service and support to make sure that your online business operates smoothly and effectively.
          Whether you're just getting started with e-commerce or hoping to upgrade your current business, we stand by you and provide Walmart WFS, Amazon Wholesale FBA,
          inventory management, order fulfillment, shipping, and customer support.
        </p>
      </div>

      <div className="w-full max-w-4xl mb-16 px-2">
        <h2 className="text-2xl font-semibold  mb-4 text-center">Step  <span className='font-bold text-orange-500' > 01</span></h2>
        <div className="flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <img src={step1} alt="Step 01" className="w-full h-auto" />
          </div>
          <div className="lg:w-1/2 lg:pl-8">
            <h3 className="text-xl font-bold text-gray-700 mb-2">Easy & Smooth Onboarding</h3>
            <p className="text-gray-600">
              You don’t require any prior knowledge of seller platforms, company development, LLCs, or paperwork. Together, our staff will guide you through each step of our simplified onboarding procedure.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full max-w-4xl mb-16 px-2">
        <h2 className="text-2xl font-semibold  mb-4 text-center">Step<span className='font-bold text-orange-500' > 02</span></h2>
        <div className="flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <img src={step2} alt="Step 02" className="w-full h-auto" />
          </div>
          <div className="lg:w-1/2 lg:pl-8">
            <h3 className="text-xl font-bold text-gray-700 mb-2">We Successfully Build Your Store</h3>
            <p className="text-gray-600">
              You’ll have your own e-commerce business on the world’s greatest platform and be ready to sell within two weeks. We collaborate with many of the brands you know and love. As soon as you make your first sale, your assets will begin to increase.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full max-w-4xl px-2">
        <h2 className="text-2xl font-semibold  mb-4 text-center">Step <span className='font-bold text-orange-500' > 03</span></h2>
        <div className="flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <img src={step3} alt="Step 03" className="w-full h-auto" />
          </div>
          <div className="lg:w-1/2 lg:pl-8">
            <h3 className="text-xl font-bold text-gray-700 mb-2">We Boost Your Store's and Sales Performance</h3>
            <p className="text-gray-600">
            We grow your shop and launch our strong sourcing and fulfillment methods, including wholesale FBA, private label, and FBM. Manage your sales and earnings in real-time with the Amazon Seller app, and get money straight from Amazon every two weeks.


            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
