import React from 'react';
import './OneSideImageText.css';

export const ListOpposeOneSideImageText = ({ imageSrc, listItems }) => {
  return (
    <div className="one-side-image-text flex flex-col lg:flex-row items-center lg:items-start">
        <div className="text-container lg:w-1/2 w-full px-4 lg:px-8">
        <ul className="list-none text-left text-gray-700">
          {listItems.map((item, index) => (
            <li key={index} className="mb-2">
              <i className="li-ico fas fa-check font-bold md:mr-2 mt-2 text-[#FF8818]"></i>
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="image-container lg:w-1/2 w-full">
        <img
          className="img-banner w-full h-auto"
          src={imageSrc}
          alt="E-Commerce"
        />
      </div>
    </div>
  );
}

export default ListOpposeOneSideImageText;
