import React, { useState } from 'react';
import video from '../../Images/Video.mp4';
import { Link } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';

const Banner = () => {
  const [loader,setLoader] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    date: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const validateForm = () => {
    setLoader(true)
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.phone) newErrors.phone = 'Phone is required';
    if (!formData.date) newErrors.date = 'Date is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        console.log(formData)
        const response = await fetch('https://amzvistas-backend-fwve.vercel.app/api/messages', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });

        if (response.ok) {
          setLoader(false)
          alert('Message successfully sent.');
          setFormData({
            name: '',
            phone: '',
            date: '',
            email: '',
            message: ''
          });
        } else {
          setLoader(false)
          alert('Failed to send message. Please try again.');
        }
      } catch (error) {
        setLoader(false)
        console.error('Error:', error);
        alert('Failed to send message. Please try again.');
      }
    }
  };

  return (
    <div className="relative flex flex-col md:flex-row items-center justify-between py-36 bg-black bg-opacity-50 text-white">
      {/* Background video */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover -z-10"
        src={video}
        autoPlay
        muted
        loop
      ></video>

      {/* Banner Content */}
      <div className="container mx-auto px-4 md:px-8 lg:px-16 flex flex-col md:flex-row justify-between items-center">
        {/* Text Content */}
        <div className="md:w-1/2 text-center md:text-left mb-8 md:mb-0">
          <h1 className="text-3xl lg:text-5xl font-bold mb-4">
            Building Foundation for Success with<br /> E-commerce Automation Services
          </h1>
          <p className="mb-6 text-lg lg:text-xl">
            Without a doubt, Amz Vistas is a thriving company that assists customers in generating cash by planting the seeds of enterprises at Walmart and Amazon. Our infrastructure, license agreements, and a large staff of professionals help clients all over the globe build and run successful e-commerce automation platforms. Find out why so many people are choosing Amz Vistas as their e-commerce revolution partner.
          </p>
          <Link to={"/Contact-us"} >
            <button className="bg-blue-500 text-white py-2 px-6 rounded-full hover:bg-blue-600 transition duration-300 mainthemecolor">
              Get a Free Consultation Now!
            </button>
          </Link>
        </div>

        {/* Contact Form */}
        <div className="md:w-4/12 bg-white bg-opacity-90 p-8 rounded-lg shadow-lg text-black">
          <h2 className="text-2xl font-bold mb-4 text-center md:text-center">
            Get In <span style={{ color: '#F69500' }}>Touch</span> With Us
          </h2>
          <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
            <input
              className="w-full p-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <p className="text-red-500">{errors.name}</p>}
            <input
              className="w-full p-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              type="text"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && <p className="text-red-500">{errors.phone}</p>}
            <input
              className="w-full p-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              type="date"
              name="date"
              placeholder="Date"
              value={formData.date}
              onChange={handleChange}
            />
            {errors.date && <p className="text-red-500">{errors.date}</p>}
            <input
              className="w-full p-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p className="text-red-500">{errors.email}</p>}
            <textarea
              className="w-full p-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            {errors.message && <p className="text-red-500">{errors.message}</p>}

            {

loader ? (
  <Circles
  height="30"
  width="30"
    color="#FFAC42"
    ariaLabel="circles-loading"
    wrapperClass="w-full flex justify-center"
    visible={true}
  />
) : (<button
  
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-full hover:bg-blue-600 transition duration-300 mainthemecolor"
            >

            
              Send
            </button>)
            }
          </form>
        </div>
      </div>
    </div>
  );
};

export default Banner;