import React from 'react';

const MapContact = () => {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-center py-16 px-4 lg:px-8 mx-auto max-w-screen-lg">
    <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3030.5292296031176!2d-74.47008148460353!3d40.52762197935213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3c6429144de6d%3A0x6e5e7be57e8581ee!2s371%20Hoes%20Ln%20Ste%20200%2C%20Piscataway%2C%20NJ%2008854%2C%20USA!5e0!3m2!1sen!2sin!4v1687291835031!5m2!1sen!2sin"
        width="100%"
        height="350"
        allowFullScreen=""
        loading="lazy"
        className="border-0"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>

    <div className="w-full lg:w-1/2 lg:pl-8">
      <div className="mb-8">
        <h3 className="text-xl font-bold text-gray-700 mb-2">371 Hoes Ln</h3>
        <p className="text-gray-600">371 Hoes Ln Ste 200, Piscataway, NJ 08854, USA</p>
        <a href="https://www.google.com/maps?q=371+Hoes+Ln+Ste+200,+Piscataway,+NJ+08854,+USA&hl=en" target="_blank" rel="noopener noreferrer" className="text-blue-600">View larger map</a>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-bold text-gray-700 mb-2">Contact</h3>
        <p className="text-gray-600 mb-2">
          <span className="font-bold">Phone:</span> (848) 893 1877
        </p>
        <p className="text-gray-600">
          <span className="font-bold">Email:</span> partnerships@walstreamz.com
        </p>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-bold text-gray-700 mb-2">Text/ Call us to schedule a meeting with a Sr. Business Consultant</h3>
      </div>

      <div className="flex items-center">
        <input type="email" placeholder="Email" className="p-2 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-600 w-2/3" />
        <button className="bg-blue-600 text-white px-4 py-2 rounded-r-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 w-1/3 mainthemecolor">Send</button>
      </div>
    </div>
  </div>

  );
};

export default MapContact;
