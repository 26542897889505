import React from 'react'
import MegaMenu from '../../Components/Navbar/Navbar'
import AmazonBanner from '../../Components/Banner/AmazonBanner'
import { OneSideImageText } from '../../Components/OneSideImageText/OneSideImageText'
import Revolutionize from '../../Components/Revolutionize/Revolutionize'
import Revolution_Card from '../../Components/Revolutionize/Revolution_Card'
import CardSlider from '../../Components/Slider_Card/Slider_Parent'
import Discover_Excellence from '../../Components/Discover_Excellence/Discover_Excellence'
import DiscoverExcellence2 from '../../Components/Discover_Excellence/Discover_Excellence2'
import { ListOneSideImageText } from '../../Components/OneSideImageText/ListOneSideImageText'
import ListOpposeOneSideImageText from '../../Components/OneSideImageText/ListOppose'
import Reviews from '../../Components/Reviews/Reviews'
import Footer from '../../Components/Footer/Footer'
import AreAware from '../../Components/AreAware/AreAware'
import FeelConfident from '../../Components/AreAware/FeelConfident'
import ShopifyBanner from '../../Components/Banner/ShopifyBanner'
import DiscoverExcellence23 from '../../Components/Discover_Excellence/Discover_Excellence3'
import { OppositeImageText } from '../../Components/OneSideImageText/OPPoseOneSideImage'
import img1 from '../../Images/Untitled design (39).png'
import AreAware2 from '../../Components/AreAware/AreAware2'
import ShopifyReviews from '../../Components/Reviews/ShopifyReviews'
import shopify1 from '../../Images/shopify1.svg'
import shopify2 from '../../Images/Shopify2.png'
import shopify3 from '../../Images/Shopify3.png'
import shopify4 from '../../Images/Shopify4.png'

const Shopify_Automation = () => {
 

  return (
    <>
      <MegaMenu/>
      <div className='pt-12' >
      <ShopifyBanner/>
      </div>
      <OneSideImageText
      imageSrc={shopify1}
      heading="Experience Success with <span class='gradient-text' >Shopify Automation Services.<span/> "
      text1="Staying ahead of the competition requires innovation. That’s where our Done For You Shopify Store can help. As a top Shopify automation agency, we specialize in creating automated stores that save you time and enhance overall business performance. Our experts streamline processes to drive sales growth and increase customer satisfaction levels, ensuring automation supports your brand expansion seamlessly."
    //   text2="Our journey has been nothing short of remarkable. Our team has achieved a whopping $300 million in revenue through countless challenges – and we're just getting started! What sets us apart is our rock-solid business model that consistently spells success. We're not here to make promises; we're here to deliver, and we're so confident in our abilities that we offer you something extraordinary – a contractual guarantee of a return on investment (ROI) throughout our partnership."
      buttonText="Schedule A Free Consultation Now!"
    />

<Revolution_Card
              title="Unleashing the Power of<span class='gradient-text' > Shopify Automation.<span/>"
              description="Empower your finances to work for you with Amz Vistas."
            />

<CardSlider/>
<Revolution_Card
              title="Amz Vistas is Here – Your Ultimate Shopify Destination!"
              title2="<span class='gradient-text' >Instead of experimenting randomly, adopt our proven strategies for success with Amz Vistas. </span>"
              buttonText={"Schedule A Free Consultation Now!"}
            />
<DiscoverExcellence23/>


<OneSideImageText
      imageSrc={shopify2}
      heading="Unlocking the True Potential of Your Business"
      text1="An automated Shopify store never sleeps! Imagine having a virtual assistant on call 24/7 to handle customer inquiries, process orders, and provide support. With Amz Vistas's automation services, your business remains open to serve customers from different time zones around the globe! "
    //   text2="Our journey has been nothing short of remarkable. Our team has achieved a whopping $300 million in revenue through countless challenges – and we're just getting started! What sets us apart is our rock-solid business model that consistently spells success. We're not here to make promises; we're here to deliver, and we're so confident in our abilities that we offer you something extraordinary – a contractual guarantee of a return on investment (ROI) throughout our partnership."
    />
    <OppositeImageText
       imageSrc={shopify3}
       heading="Decisive Decision-Making Based on Data"
       text1="Data is at the heart of modern business. That's why Amz Vistas's automation services incorporate cutting-edge analytics tools that offer insights into customer behaviors, sales trends, and more. Gain the power to make informed decisions and continually optimize your store's performance."    
    />
<OneSideImageText
      imageSrc={img1}
      heading="Scaling Made Simple Through Our Innovative Approach"
      text1="As your business expands, so should your online store. Amz Vistas's automated Shopify solutions are built to easily scale with you as customer numbers increase and product offerings expand. No need to worry about outgrowing your current setup – we've got your scalability covered!"
    //   text2="Our journey has been nothing short of remarkable. Our team has achieved a whopping $300 million in revenue through countless challenges – and we're just getting started! What sets us apart is our rock-solid business model that consistently spells success. We're not here to make promises; we're here to deliver, and we're so confident in our abilities that we offer you something extraordinary – a contractual guarantee of a return on investment (ROI) throughout our partnership."
    />

<AreAware2/>
<ShopifyReviews/>
<FeelConfident/>
<Footer/>
    
    </>
  )
}

export default Shopify_Automation