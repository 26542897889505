import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Pages/Home/Home';
import Contact from './Pages/Contact/Contact';
import Our_Process from './Pages/Our_Process/Our_Process';
import About_us from './Pages/About-us/About_us';
import Amazon_FBA_Automation from './Pages/Amazon_FBA_Automation/Amazon_FBA_Automation';
import Shopify_Automation from './Pages/Shopify_Automation/Shopify_Automation';
import Walmart_Automation from './Pages/WalmartAutomation/WalmartAutomation';
import Terms_Condition from './Pages/Terms_Condition/Terms_Condition';
import Privacy_Policy from './Pages/Privacy_Policy/Privacy_Policy';
import ScrollToTop from './Components/ScrollonTop/ScrollonTop';
function App() {
  return (
   <>
   <BrowserRouter>
   <Routes>
   <Route
      path={"/"}
      element={
        <>
      <ScrollToTop />
      <Home/>
        </>
      }
    />

<Route
      path={"/Contact-us"}
      element={
        <>
     <ScrollToTop />
     <Contact/>
        </>
      }
    />


<Route
      path={"/Our-Process"}
      element={
        <>
      <ScrollToTop />
     <Our_Process/>
        </>
      }
    />
<Route
      path={"/About-us"}
      element={
        <>
      <ScrollToTop />
     <About_us/>
        </>
      }
    />
<Route
      path={"/Amazon-fba-automation"}
      element={
        <>
      <ScrollToTop />
     <Amazon_FBA_Automation/>
        </>
      }
    />


<Route
      path={"/Shopify-Automation"}
      element={
        <>
      <ScrollToTop />
     <Shopify_Automation/>
        </>
      }
    />


<Route
      path={"/Walmart-Automation"}
      element={
        <>
     <ScrollToTop />
     <Walmart_Automation/>
        </>
      }
    />



<Route
      path={"/Terms-Condition"}
      element={
        <>
      <ScrollToTop />
     <Terms_Condition/>
        </>
      }
    />
<Route
      path={"/Privacy-Policy"}
      element={
        <>
      <ScrollToTop />
     <Privacy_Policy/>
        </>
      }
    />



  
   </Routes>
   </BrowserRouter>
  </>
  );
}

export default App;
