import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img1 from '../../Images/Walmart/Wamart2.webp';
import img2 from '../../Images/Walmart/Walmart3.webp';
import img3 from '../../Images/Walmart/WAlmart4.webp';
import img4 from '../../Images/Walmart/Walmart5.webp';


const WalmartReviews = () => {
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 0,
    cssEase: 'linear',
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="my-12">
      <h1 className="text-2xl pb-12 text-center font-bold sm:text-3xl md:text-3xl lg:text-3xl gradient-text">
      Go with Amz Vistas

        <hr style={{ width: '10%', margin: 'auto', border: '2px solid #FF9900', marginTop: '6px' }} />
      </h1>

      <div className="w-3/4 mx-auto">
        <Slider className="mx-auto" {...settings}>
          <div>
            <img src={img1} alt="" />
          </div>
          <div>
            <img src={img2} alt="" />
          </div>
          <div>
            <img src={img3} alt="" />
          </div>
          <div>
            <img src={img4} alt="" />
          </div>
          <div>
            <img src={img4} alt="" />
          </div>
          <div>
            <img src={img2} alt="" />
          </div>
          <div>
            <img src={img1} alt="" />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default WalmartReviews;
