import React from 'react';
import './Visionary_Leadership.css';

const Visionary_Leadership = () => {
  return (
    <div className='Visionary-bg items-center justify-center'>
      <h1 className='md:text-3xl font-bold text-gray-800 mb-4 text-center text-xl'>
        Discover Visionary Leadership! With decades of expertise in E-Commerce <br /> Growth.
      </h1>
      <div className='max-w-lg px-6 py-4 bg-white rounded-lg shadow-lg text-center m-auto sm:max-w-xl md:max-w-2xl lg:max-w-3xl xl:max-w-4xl'>
        <div className='text-left'>
          <p className='text-base text-gray-700 mb-2'>
            Eric leads Amazon Automation, Walmart Automation, and more with 10 years in the marketplace. Unleash success with his aggressive tactics in the dynamic realm of E-Commerce Automation.
          </p>
          <h1 className='text-xl font-bold text-gray-800 mb-1'>
            Eric Blair
          </h1>
          <h2 className='text-base text-gray-600'>
            Founder
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Visionary_Leadership;
