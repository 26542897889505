import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ThreeImageSlider.css';
import Partner1 from '../../Images/Partner1.png'
import Partner2 from '../../Images/Partner2.png'
import Partner3 from '../../Images/Partner3.png'
import Partner4 from '../../Images/Partner4.png'
import Partner5 from '../../Images/Partner5.png'
import Partner6 from '../../Images/Partner6.png'
import Partner7 from '../../Images/Partner7.png'

const PartnerSlider = () => {
  return (
    <div className="py-8">
      <h2 className="text-2xl font-bold text-gray-800 text-center mb-6">Our Trusted Partners</h2>
      <Carousel showArrows={true} showIndicators={false} showStatus={false} infiniteLoop={true} autoPlay={true} interval={1000} stopOnHover={true} swipeable={true} emulateTouch={true} centerMode={true} className="max-w-3xl mx-auto">
        <div>
          <img  className='partner-Slide-image'   src={Partner1} alt="Partner 1" />
        </div>
        <div>
          <img   className='partner-Slide-image'  src={Partner2} alt="Partner 2" />
        </div>
        <div>
          <img  className='partner-Slide-image'   src={Partner3} alt="Partner 3" />
        </div>
        <div>
          <img   className='partner-Slide-image'  src={Partner4} alt="Partner 3" />
        </div>
        <div>
          <img   className='partner-Slide-image'  src={Partner5} alt="Partner 3" />
        </div>
        <div>
          <img   className='partner-Slide-image'  src={Partner6} alt="Partner 3" />
        </div>
        <div>
          <img   className='partner-Slide-image'  src={Partner7} alt="Partner 3" />
        </div>
      </Carousel>
    </div>
  );
}

export default PartnerSlider;
