import React from 'react'
import MegaMenu from '../../Components/Navbar/Navbar'
import AmazonBanner from '../../Components/Banner/AmazonBanner'
import { OneSideImageText } from '../../Components/OneSideImageText/OneSideImageText'
import Revolutionize from '../../Components/Revolutionize/Revolutionize'
import Revolution_Card from '../../Components/Revolutionize/Revolution_Card'
import CardSlider from '../../Components/Slider_Card/Slider_Parent'
import Discover_Excellence from '../../Components/Discover_Excellence/Discover_Excellence'
import DiscoverExcellence2 from '../../Components/Discover_Excellence/Discover_Excellence2'
import { ListOneSideImageText } from '../../Components/OneSideImageText/ListOneSideImageText'
import ListOpposeOneSideImageText from '../../Components/OneSideImageText/ListOppose'
import Reviews from '../../Components/Reviews/Reviews'
import Footer from '../../Components/Footer/Footer'
import AreAware from '../../Components/AreAware/AreAware'
import FeelConfident from '../../Components/AreAware/FeelConfident'
import WallmartBanner from '../../Components/Banner/Walmart_Banner'
import CardSlider3 from '../../Components/Slider_Card/SliderParent3'
import DiscoverExcellence4 from '../../Components/Discover_Excellence/DiscoverExcellence4'
import WallmartFeelConfident from '../../Components/AreAware/WallmartFellConfident'
import WallmartAutomationStrategy from '../../Components/AreAware/WalmartAutomationStrategy'
import WalmartReviews from '../../Components/Reviews/Walmart'
import WallmartImage1 from '../../Images/Wallmart1.png'
import WallmartImage2 from '../../Images/WallmartImage2.jpg'
import WallmartImage3 from '../../Images/WallmartImage3.jpg'
import WallmartImage4 from '../../Images/WallmartImage4.jpg'


const Walmart_Automation = () => {
    const imageSrc = WallmartImage2
    const listItems = [
      "LLC + EIN + W9 Setup Assistance",
      "Website Development & Design",
      "LLC + EIN + W9 Setup Assistance",
      "Product study and analysis that makes a lot of money",
      "How to become a Walmart Store seller",
    ];
  
const image3= WallmartImage3
const listItems3 = [
    "Services for customer service",
    "Management of logistics",
    "Management of returns",
    "Catalog of products can grow indefinitely",
    "Handling of relationships with suppliers",
  ];

  const image2= WallmartImage4
  const listItems2 = [
      "Streamlined Order Fulfillment process (via Warehouse, Home Depot, Costco, BJS and more",
      "Report on Monthly Productivity",
      "Resources set aside to help you grow your store",
      "Everything from A to Z",
      "In charge of running your whole business",
    ];


  return (
    <>
      <MegaMenu/>
      <div className='pt-12' >
      <WallmartBanner/>
      </div>
      <OneSideImageText
      imageSrc={WallmartImage1}
      heading="Our  <span class='gradient-text' > Walmart Automation Model Puts Sales First, Maximizes Profits, and Is Fully Managed by Us for You"
      text1="Imagine the opportunity in a market with less than 100,000 sellers and $14 billion in e-commerce sales. Now is the perfect time to start a profitable business with our Walmart Dropshipping Automation Service, which includes Amz Vistas Wholesale and OA Product Strategies."
    //   text2="Our journey has been nothing short of remarkable. Our team has achieved a whopping $300 million in revenue through countless challenges – and we're just getting started! What sets us apart is our rock-solid business model that consistently spells success. We're not here to make promises; we're here to deliver, and we're so confident in our abilities that we offer you something extraordinary – a contractual guarantee of a return on investment (ROI) throughout our partnership."
      buttonText="Schedule A Free Consultation Now!"
    />

<Revolution_Card
              title="Our Walmart Automation Model Guarantees"
              title2="<span class='gradient-text' > Long-Term Success, High Earnings, and Profit<span/>"
        buttonText={"BOOK CALL"}
            />

<CardSlider3/>

<DiscoverExcellence4/>


<ListOneSideImageText imageSrc={imageSrc} listItems={listItems} />

<ListOpposeOneSideImageText imageSrc={image3} listItems={listItems3} />


<ListOneSideImageText imageSrc={image2} listItems={listItems2} />



<WallmartAutomationStrategy/>

<WalmartReviews/>
<WallmartFeelConfident/>


<Footer/>
    
    </>
  )
}

export default Walmart_Automation