import React from 'react';
import './Discover.css';

const DiscoverExcellence23 = () => {
  return (
    <div className="text-center my-14 px-4 lg:px-24 flex flex-col justify-center items-center">
      <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 text-gray-800">
        <span style={{"borderBottom" :"5px solid #FD9900"}} className='gradient-text'>The Shopify dropshipping game-changer!</span>
      </h1>
    </div>
  );
};

export default DiscoverExcellence23;
