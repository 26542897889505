import React from 'react';
import './OneSideImageText.css';
import { Link } from 'react-router-dom';

export const OneSideImageText = ({ imageSrc, heading, text1, text2, buttonText }) => {
  return (
    <div className="one-side-image-text ">
      <div className="image-container">
        <img className='img-banner' src={imageSrc} alt="E-Commerce" />
      </div>
      <div className="text-container">
        <h1  className='text-12xl'  dangerouslySetInnerHTML={{ __html: heading }} ></h1>
        <p>{text1}</p>
        <p>{text2}</p>
        <Link to={"/Contact-us"} >
        
        {buttonText && (
          <button className="consultation-button mainthemecolor">
            {buttonText}
          </button>
        )}
        </Link>
      </div>
    </div>
  );
}
