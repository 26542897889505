import React from 'react';
import './OneSideImageText.css';
import { Link } from 'react-router-dom';

export const OppositeImageText = ({ imageSrc, heading, text1, text2, buttonText }) => {
  return (
    <div className="one-side-image-text">
     
      <div className="text-container">
      <div className="flex items-start justify-center">
      <p className="text-lg mr-2 font-bold">4.5+ rating over the past 3+ years​</p>
      <div className="flex mt-2">
        <i className="fa-solid fa-star text-yellow-500"></i>
        <i className="fa-solid fa-star text-yellow-500"></i>
        <i className="fa-solid fa-star text-yellow-500"></i>
        <i className="fa-solid fa-star text-yellow-500"></i>
        <i className="fa-solid fa-star text-yellow-500"></i>
      </div>
    </div>

        <h1>{heading}</h1>
        <p>{text1}</p>
        <p>{text2}</p>
        <Link to={"/Contact-us"} >
        {buttonText && (
          <button className="consultation-button mainthemecolor">
            {buttonText}
          </button>
        )}
        
        
        </Link>

        {/* <button className="consultation-button">
          {buttonText}
        </button> */}
      </div>
      <div className="image-container">
        <img className='img-banner' src={imageSrc} alt="E-Commerce" />
      </div>
    </div>
  );
}
