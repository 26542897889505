import React from 'react'
import MegaMenu from '../../Components/Navbar/Navbar'
import Banner from '../../Components/Banner/HomeBannner'
import { OneSideImageText } from '../../Components/OneSideImageText/OneSideImageText'
import Discover_Excellence from '../../Components/Discover_Excellence/Discover_Excellence'
import Revolutionize from '../../Components/Revolutionize/Revolutionize'
import Four_Card from '../../Components/Four_Card/Four_Card'
import { OppositeImageText } from '../../Components/OneSideImageText/OPPoseOneSideImage'
import Visionary_Leadership from '../../Components/VisionaryLeadership/Visionary_Leadership'
import PartnerSlider from '../../Components/Testimonal_Slider/Testimonal_Slider'
import Footer from '../../Components/Footer/Footer'
// import CardSlider from '../../Components/Slider_Card/Slider_Card'
// import Slider_Parent from '../../Components/Slider_Card/Slider_Parent'
import Reviews from '../../Components/Reviews/Reviews'
import CardSlider from '../../Components/Slider_Card/Slider_Parent'
import CardSlider1 from '../../Components/Slider_Card/Slider_Parent1'
import img1 from '../../Images/Amz Vistas.png'
import AmazonSeller from '../../Images/Amazon-seller.png'
import DiscoverGatway from '../../Images/DiscoverGateWay.png'
import Reveal from '../../Images/Reveal.jpg'
import explore from '../../Images/logistics.png'
const   Home = () => {
  return (
    <>
    <MegaMenu/>
    <Banner/>
    <OneSideImageText
      imageSrc={AmazonSeller}
      heading="Revolutionize Your E-Commerce Empire with Top-Notch Automation Services That Deliver Results"
      text1="Welcome to Amz Vistas – where we don't just manage e-commerce assets; we elevate them to unprecedented heights! Imagine a team of over 500 seasoned experts, all geared up to supercharge your online marketplace. We're not just service providers; we're your partners in profit, with a track record spanning more than ten years in product development and e-commerce marketplace operations. We are among the best e-commerce automation companies."
      text2="Our journey has been nothing short of remarkable. Our team has achieved a whopping $300 million in revenue through countless challenges – and we're just getting started! What sets us apart is our rock-solid business model that consistently spells success. We're not here to make promises; we're here to deliver, and we're so confident in our abilities that we offer you something extraordinary – a contractual guarantee of a return on investment (ROI) throughout our partnership."
      buttonText="Get a Free Consultation Now!"
    />
    <div className='my-12' >
    <div style={{ width: '60%', borderBottom: '4px solid #FA9923' , margin :"auto" }}></div>
    </div>
<Discover_Excellence/>

<CardSlider/>

<Revolutionize/>

    <div style={{ width: '60%', borderBottom: '4px solid #FA9923' , margin :"auto" }}></div>

<OneSideImageText
      imageSrc={DiscoverGatway}
      heading="Discover the Gateway to Your E-commerce Success! Turn your dreams of a thriving e-commerce business into reality with Amz Vistas."
      text1="We are a dynamic powerhouse, seamlessly blending logistics, wholesale distribution, and e-commerce seller management under one robust roof. With our headquarters in Los Angeles and strategically positioned warehouses and prep centers in Texas, Florida, New York, and Michigan, we are poised to support your e-commerce endeavors efficiently."      
      text2={"Our mission is grand: to pave the way for effortless entry into the vast $5 trillion e-commerce automation platform. We cater to both aspiring retail investors and seasoned institutional players with equal finesse. Your journey to e-commerce triumph begins here!"}
      buttonText="Get a Free Consultation Now!"

    />
<CardSlider1/>

<OneSideImageText
      imageSrc={Reveal}
      heading="Reveal the <span class='gradient-text'> Numbers </span>"
      text1="Amz Vistas is a dynamic powerhouse, seamlessly blending logistics, wholesale distribution, and e-commerce seller management under one robust roof. With our headquarters in Los Angeles and strategically positioned warehouses and prep centers in Texas, Florida, New York, and Michigan, we are poised to support your e-commerce endeavors efficiently."     
      text2={"Our mission is grand: to pave the way for effortless entry into the vast $5 trillion e-commerce automation platform. We cater to both aspiring retail investors and seasoned institutional players with equal finesse. Your journey to e-commerce triumph begins here!"}
      buttonText="Get A Free Consultation Now!"
    />
<Four_Card/>
<OppositeImageText
        imageSrc={img1}
        heading="Experience Success Stories!"
        text1="Our visionary leaders seized the opportunity to elevate the traditional done-for-you (DFY) Amazon business model into an innovative, data-driven approach. Rooted in robust logistics and fueled by constant innovation, Amz Vistas emerged as a leader in e-commerce automation. We exceeded our goal of creating a model where our business and clients’ interests are intricately intertwined. Witness a journey with us where customer success is a testament to our shared prosperity!"
        buttonText="Schedule A Free Consultation"
      />
      <div style={{ width: '60%', borderBottom: '4px solid #FA9923' , margin :"auto" }}></div>


<OneSideImageText
      imageSrc={explore}
      heading="Explore <span class='gradient-text'>  Amz Vistas  logistics </span> world with an exclusive tour of our <span class='gradient-text'>  Central Levittown facilities.  </span>"
      text1="Dive into the logistics process, operations, private label brand development, and wholesale journey. We prioritize transparency, welcoming all to witness the heart of our operations."
      text2={"Schedule your tour today for a firsthand experience of Amz Vistas's commitment to openness and innovation."}     
      buttonText="Get A Free Consultation Now!"
    />


<Visionary_Leadership/>


<PartnerSlider/>

<Reviews/>

<Footer/>
    </>
  )
}

export default Home