import React from 'react';
import './Slider.css'
const SliderCard = ({ imageSrc, title, description }) => {
  return (
    <div className="bg-white w-72 mx-auto rounded-lg overflow-hidden shadow-lg slider-card-02    flex flex-col justify-between p-2">
      <img src={imageSrc} alt={title} className="w-24 m-auto" />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{title}</div>
        <p className="text-gray-700 text-base">
          {description}
        </p>
      </div>
    </div>
  );
}

export default SliderCard;
