import React from 'react';
import AmzBanner from '../../Images/AmzBanner.jpg'

const ShopifyBanner = () => {
  return (
    <div className="relative bg-cover bg-center h-48 sm:h-64 md:h-96 lg:h-[30vw] flex flex-col items-center justify-center " style={{ backgroundImage: `url(${AmzBanner})` , padding :"20px" }}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative flex items-center justify-center h-full">
        <h1 className="text-white text-2xl md:text-4xl lg:text-6xl font-bold text-center">
        <span className="text-orange-500">Shopify</span>  Automation Service
        </h1> 
      </div>
    </div>
  );
};

export default ShopifyBanner;
