import React from 'react'
import MegaMenu from '../../Components/Navbar/Navbar'
import AboutBanner from '../../Components/Banner/About_Banner'
import { OneSideImageText } from '../../Components/OneSideImageText/OneSideImageText'
import { OppositeImageText } from '../../Components/OneSideImageText/OPPoseOneSideImage'
import AboutSec from '../../Components/Discover_Excellence/AboutSec'
import img1 from '../../Images/Untitled design (38).png'
import Reviews from '../../Components/Reviews/Reviews'
import Footer from '../../Components/Footer/Footer'
import MapContact from '../../Components/Map_Section/Map_Section'
import aboutimage1 from '../../Images/About1.png'
import aboutimage2 from '../../Images/About2.png'

const About_us = () => {
  return (
   <>
     <MegaMenu/>
     <div className='pt-20' >
   <AboutBanner/>
     </div>
   
     <OneSideImageText
      imageSrc={aboutimage1}
      heading="Our <span class='gradient-text' >  Story</span>"
      text1="A network of hundreds of clients, six unique warehouse locations across the US, and four domestic and foreign offices now utilize our infrastructure. Quality, performance, and transparency are the foundations of each client's involvement as we grow client wealth and enhance the value of their digital assets. The Amz Vistas team has earned over five figures in income through online sales and is devoted to working as your business partner in the digital age."
      text2={"The management team at Amz Vistas has extensive experience in product development and e-commerce marketplaces, and they are dedicated automation service providers. When our founders encountered challenges in the e-commerce business, they established Amz Vistas to provide a platform that automates and streamlines the key areas needed to run a successful e-commerce automation model."}
    />
<OppositeImageText
        imageSrc={aboutimage2}
        heading="Our Vision"
        text1="Our aim is to empower our clients' businesses to embrace evolving technologies in the AI era. By 2024, our objective is to assist our clients in achieving a growth rate of 100 times their initial investments. We prioritize forming partnerships because we believe that mutual growth is of utmost significance."
        // buttonText="Schedule A Free Consultation"
      />

<OneSideImageText
      imageSrc={img1}
      heading="Why consider  <span class='gradient-text' >  Amz Vistas?</span>"
      text1="Amz Vistas is not just an ordinary managed e-commerce supplier. Our e-commerce growth agency and consulting services are built upon a sustainable infrastructure. At our core, we are a wholesale distributor and shipping company, fully aware that reaching the level of automation required for a successful online store is nearly impossible without an expert team. That's why we created a platform designed to elevate your e-commerce automation business to new heights."
text2={"We are confident in our ability to help you achieve your goals, offering a buy-back guarantee in writing. We handle all order fulfillment, returns, and more, ensuring an easier process and comprehensive management of your company by the most qualified staff in the field."}


    />
    <MapContact/>
    <AboutSec/>
    <Reviews/>

<Footer/>
   </>
  )
}

export default About_us