import React from 'react'
import MainContentPrivacy from '../../Components/Privacy_Policy_Comp/Privacy_Policy_Comp'
import MegaMenu from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'

const Privacy_Policy = () => {
  return (
    <>
    <MegaMenu/>
    <div className='' >
    <MainContentPrivacy/>
    </div>
    <Footer/>
    </>
  )
}

export default Privacy_Policy